@import "@/assets/constants/index.scss";
button.single-button.add-preset-button {
  position: absolute;
  top: -16px;
  right: 0px;
  margin-right: 0px;
  padding: 6px 7px !important;
}

h6.small-grey.preset-header {
  margin-bottom: 32px;
}
