@import "@/assets/constants/index.scss";
.parent-item {
  &.parent-full-size {
    width: auto;
  }

  .score-name {
    width: 192px;
  }

  .score-value {
    width: 136px;
    margin-left: 16px;
  }

  .age-value {
    width: 136px;

    &.last-value {
      margin-left: 16px;
    }
  }
}

.scope-name-parent {
  width: 192px;
  height: 40px;
  border: 2px dotted $c-blue-grey3;
  border-radius: 8px;
  padding: 0px 8px 0px 16px;

  span {
    color: $c-grey2;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }
}

.el-select.operator-select {
  .select-trigger {
    .el-input {
      width: 136px;
      border-radius: 8px;
      border: 1px solid $c-blue-grey2;

      .el-input__inner {
        background-color: transparent;
      }
    }
  }
}
